import log from './Logger'
function DefineColumns (returnedColumns) {
    const tableStructure = [
        { keyName: "id", label: "The Id", index: 99 }
        , { keyName: "code", label: "Car Code", index: 98 }
        , { keyName: "date_uploaded", label: "Car Uploaded Date", index: 97 }
        , { keyName: "active", label: "Car Active", index: 96 }
        , { keyName: "valid_car", label: "Car Valid", index: 2 }
        , { keyName: "l_km_c", label: "L/100KM City", index: 8, tooltip: "Litres per 100 Kilometres with City Driving" }
        , { keyName: "l_km_m", label: "L/100KM Mixed", index: 7, tooltip: "Litres per 100 Kilometres with Mixed Driving" }
        , { keyName: "l_km_h", label: "L/100KM Highway", index: 6, tooltip: "Litres per 100 Kilometres with Highway Driving" }
        , { keyName: "l_tank", label: "Tank Size (L)", index: 5, tooltip: "Size of Fuel Tank in Litres" }
        , { keyName: "d_max", label: "Estimated Max KMs", index: 4, tooltip: "Estimated Maximum Distance per Tank in Kilometers" }
        , { keyName: "d_min", label: "Estimated Min KMs", index: 3, tooltip: "Estimated Minimum Distance per Tank in Kilometers" }
    ]

    let columns = returnedColumns?.map((currentColumn, index) => {
        let header = tableStructure.find(tableColumn => tableColumn.keyName === currentColumn)
        if (!header)
            header = {
                keyName: currentColumn,
                label: currentColumn,
                index: index + 10
            }

        const column = {
            field: header.keyName,
            headerName: header.label,
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,    
            autoHeight: true,
            headerTooltip: header.tooltip? header.tooltip:header.label,
            cellStyle:{ padding:5, fontSize: 12, whiteSpace: "normal", lineHeight:2},             
        }
        if (header.keyName === "active" || header.keyName === "valid_bet") {
            column.cellRenderer = (params) => params.value ? 'Yes' :'No'
        }
        return {value: column, sortIndex: header.index}
    })
    if (!columns)
        columns = []
    log(columns)    
    const sortedColumns = columns.sort((a, b) => a.sortIndex - b.sortIndex).map(column => column.value)
    log(sortedColumns)    
    return sortedColumns
}
export const Helpers = {
    DefineColumns
}