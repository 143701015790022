
import './App.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useRef, useState, useEffect } from 'react'
import { css } from '@emotion/react'

import { Helpers } from './services/Columns'
import log from './services/Logger'

function App() {
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([])
  const [rowData, setRowData] = useState([])
  const [filteredRowData, setFilteredRowData] = useState([])
  const [filterText, setFilterText] = useState('')

  const grid_style = css`
    font-size:12px;
    width: 90%;
    .ag-header-cell-filtered {
      background-color: #E0E0E0 !important;
    }
  `
  const onFilterTextBoxChanged = (value) =>{
    setFilterText(value)
    gridRef?.current.api.setQuickFilter(value);
  }
  const gridLoadedEvent = (eventDetails )=> {
    eventDetails.api.sizeColumnsToFit();
  }
  const rowStyleEvent = (eventDetails) => {
    log("row style event triggered")
    // console.log(eventDetails)
  }
  
  const maxDistance = (car) => {
    log("car")
    log(car)
    let lc = 0
    if (car.model_lkm_city)
      lc = car.model_lkm_city
    let lm = 0
    if (car.model_lkm_mixed)
      lc = car.model_lkm_mixed
    let lh = 0
    if (car.model_lkm_hwy)
      lc = car.model_lkm_hwy
    const t = car.model_fuel_cap_l

    let min = 9999999
    let max = 0
    log(`min: ${min}`)
    log(`max: ${max}`)
    if (min > lc && lc !== 0)
      min = lc
    if (min > lm && lm !== 0)
      min = lm
    if (min > lh && lh !== 0)
      min = lh
      
    if (max < lc && lc !== 0)
      max = lc
    if (max < lm && lm !== 0)
      max = lm
    if (max < lh && lh !== 0)
      max = lh
    
    let total = t
    if (!t)
      total = 0

    log(`min: ${min}`)
    log(`max: ${max}`)
    log(`div: ${max/total}`)
    log(`div*100: ${max/total*100}`)
    return {
      max: total / max * 100.0,
      min: total / min * 100.0,
    }
  }
  useEffect(() => {    
    log("Fetching contents")
    async function fetchData() {
      const response = await fetch("https://car-data.lapstax.co.za/api/")
      const responseJSON = await response.json()
      log(responseJSON.cars.length)
      const parsedData = responseJSON.cars.map(car => {
        const estimatedMaxDistance = maxDistance(car.raw[0])
        const parsedBetData = {
          id: car.id,
          code: car.code,
          //uploader: car.uploader,
          date_uploaded: new Date(car.date_created).toLocaleString(),
          active: car.active,
          l_km_c: car.raw?.[0].model_lkm_city,
          l_km_m: car.raw?.[0].model_lkm_mixed,
          l_km_h: car.raw?.[0].model_lkm_hwy,
          l_tank: car.raw?.[0].model_fuel_cap_l
          , d_max: Math.round(estimatedMaxDistance.max)
          , d_min: Math.round(estimatedMaxDistance.min)
          // date_created: new Date(car.raw.code_data.time).toLocaleString(),
          // valid_car: !car.raw.code_data.isReturned
        }
        // if (!parsedBetData.valid_bet)
        //   return parsedBetData
        // if (!car.raw.code_data.results)  
        //   log(car.raw.code_data)
        log(car.raw[0])
        const carObject = car.raw[0]
        if (carObject) {
          const fields = Object.keys(carObject)
          fields.forEach((field, index) => {
            parsedBetData[`${field}`] = carObject[field]
          })
        }
        // car.raw.code_data.results.forEach((betResult, index) => {
        //   parsedBetData[`ball_${index}`] = betResult.number
        //   parsedBetData[`ball_${index}_color`] = betResult.color
        // })
        return parsedBetData
      })
      setRowData(parsedData)
      setFilteredRowData(parsedData)
      setColumnDefs(Helpers.DefineColumns(Object.keys(parsedData[0])))
    }
    fetchData()
  }, [])

  const compareAgainst = (values, value) => {
    let found = false
    for (let index = 0; index < values.length; index++) {
      if (found)
        break
      
      const element = `${values[index]}`.trim();
      log(`comparing inner against ${element} vs ${value.trim()} == ${element === value.trim()}`)
      found = element === value.trim()
    }
    if (found)
      return found
    
    for (let index = 0; index <= values.length-value.length; index++) {
      if (found)
        break

      let elementToLook = ''
      for (let innerIndex = index; innerIndex < value.length+index; innerIndex++) {
        elementToLook += `${values[innerIndex]}`.trim();        
      }
      log(`comparing against ${elementToLook} vs ${value.trim()} == ${elementToLook === value.trim()}`)
      found = elementToLook === value.trim()
    }

    return found
  }
  useEffect(() => {
    if (filterText?.length) {
      const newlyFilteredParsedData = rowData?.map(row => {
        
        if (compareAgainst([row.ball_0, row.ball_1, row.ball_2, row.ball_3, row.ball_4, row.ball_5], filterText))
          return row
        return []
      })
      setFilteredRowData(newlyFilteredParsedData?.filter(row => row !== []))
    } else {
      setFilteredRowData(rowData)
    }
    
  }, [filterText])

  return (
    <div className="App">
      <input type="text" onChange={(e) => onFilterTextBoxChanged(e.target.value)} value={filterText}/>
      <div className='ag-theme-alpine' style={{height: '800px'}} >
        <AgGridReact 
            rowData={filteredRowData} 
            columnDefs={columnDefs}
            tooltipHideDelay={4000}
            tooltipShowDelay={0}
            ref={gridRef}
            getRowStyle={rowStyleEvent}
            css={grid_style}
            rowHeight={48}
            onGridReady={gridLoadedEvent}
            onGridSizeChanged={gridLoadedEvent}
            rowSelection={'single'}
        >
        </AgGridReact>
      </div>  
    </div>
  );
}

export default App;
